<template>
  <v-row class="px-10" dense>
    <v-col cols="12" md="3">
      <v-text-field
        v-model="seller.rg"
        label="RG"
        v-mask="'##.###.###-#'"
        placeholder="xx.xxx.xxx-x"
        outlined
        rounded
        dense
      />
    </v-col>
    <v-col cols="12" md="3">
      <v-text-field
        v-model="seller.cpf_cnpj"
        label="CPF"
        v-mask="'###.###.###-##'"
        placeholder="xxx.xxx.xxx-xx"
        outlined
        rounded
        dense
      />
    </v-col>
    <v-col cols="12" md="3">
      <v-text-field
        v-model="seller.cnh"
        label="CNH"
        v-mask="'########-#'"
        placeholder="Digite o n° da Carteira de Habilitação"
        outlined
        rounded
        dense
      />
    </v-col>
    <!-- <v-col cols="12" md="3">
      <v-text-field
        v-model="seller.voter_registration"
        label="Título de eleitor"
        v-mask="'############'"
        placeholder="Digite o n° do Título de Eleitor"
        outlined
        rounded
        dense
      />
    </v-col> -->
  </v-row>
</template>

<script>
export default {
  props: {
    seller: Object,
  },
};
</script>